<template>
  <div id="requestsPendingMeView">
    <div class="row">
      <div class="col">
        <h4>requests pending my action</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-spinner :show="showSpinner"></app-spinner>
        <app-modal :show="showApprovalOrRejectionModal" :static="true" size="medium" :title="selectedAction === actions.Approve ? 'review / approve request' : selectedAction.description + ' request'">
          <transition name="slide-fade">
            <app-alert v-if="approvalOrRejectionErrorMessage" :type="errorAlertType" :message="approvalOrRejectionErrorMessage" @alertclose="approvalOrRejectionErrorMessage = ''" />
          </transition>
          <form id="actionForm" onsubmit="event.preventDefault();">
            <fieldset v-bind:disabled="isActioningRequest">
              <div class="form-group row mt-2">
                <!--<span class="font-weight-bold">Are you sure you want to {{ selectedAction.description }} this requst?</span>-->
                <span class="font-weight-bold">comment:</span>
                <textarea v-model="actionComment" rows="5" class="form-control" v-bind:required="selectedAction !== actions.Approve"></textarea>
                <div class="invalid-feedback">
                  Please enter comments for this action.
                </div>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isActioningRequest">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isActioningRequest }" @click.prevent="onActionRequestConfirmed" v-bind:disabled="isActioningRequest">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
        <employee-autocomplete v-if="currentUserCanRaiseRequestForOtherRegularEmployees" :items="searchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired :isDisabled="isActioningRequest || isSearchingEmployees" />
        <request-list :requests="requests" v-if="employeeInfo.employeeId && requests.length" :employeeId="employeeInfo.employeeId" />
        <div class="font-weight-bold" v-else-if="employeeInfo.employeeId">
          There are currently no requests pending your action
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

    .spin .spinner {
      left: -.6em;
      top: .4em;
      width: 2.5em;
      display: block;
      position: absolute;
    }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import { mapState } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import { EMPLOYEE_SEARCH_STARTED_EVENT, EMPLOYEE_SELECTED_EVENT, CLOSE_MODAL_EVENT, EDIT_REQUEST_EVENT, APPROVE_REQUEST_EVENT, REJECT_REQUEST_EVENT, RETURN_REQUEST_EVENT, CANCEL_REQUEST_EVENT, PROCESS_REQUEST_EVENT } from '@/utils/constants'
  import Events from '@/utils/events'
  import Enums from '@/utils/enums'

  import { employeeService, leaveRequestService, vacationChangeRequestService, rnrRequestService } from '@/services'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import AppSpinner from '@/components/ui/AppSpinner'
  import RequestList from '@/components/leaveRequests/RequestList'
  import EmployeeAutocomplete from '@/components/ui/EmployeeAutocomplete'
  import EmployeeInfoDisplay from '@/components/others/EmployeeInfoDisplay'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'
  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'RequestsPendingMeView',
    components: {
      EmployeeAutocomplete,
      RequestList,
      AppAlert,
      AppModal,
      AppSpinner,
      EmployeeInfoDisplay,
    },
    mixins: [GeneralMixin, AccessControlMixin, AlertMixin, ErrorMixin, LeaveRequestActionsMixin],
    data: () => ({
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        alternateEmployeeId: ''
      },
      //selectedRequestToAction: null,
      //selectedAction: Enums.Actions.Approve,
      //actionComment: null,
      //showSpinner: false,
      //showApprovalOrRejectionModal: false,
      //isActioningRequest: false,
      //actions: Enums.Actions,
      searchedEmployees: [],
      requests: [],
      isSearchingEmployees: false
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        //requests: state => state.leaveRequestModule.pendingUserAction
      }),
      //requests() {
      //  alert("RRRRRR")
      //  let a = this.pendingUserAction.values()
      //  return Array.from(a)
      //}
      //requests() {
      //  return Array.from(this.pendingUserAction.values())
      //  //if (this.pendingUserAction.size < 1) {
      //  //  return []
      //  //}
      //  //return this.pendingUserAction.get(this.currentUser.employeeId)
      //}
    },
    //watch: {
    //  requests: {
    //    deep: true,
    //    handler(newValue, oldValue) {
    //      alert('The list of colours has changed!');
    //    }
    //  }
    //},
    methods: {
      resetData() {
        this.employeeInfo.employeeId = ''
        this.employeeInfo.employeeNo = ''
        this.employeeInfo.fullName = ''
        this.selectedRequestToAction = null
        this.actionComment = null
        this.showSpinner = false
        this.showApprovalOrRejectionModal = false
        this.isActioningRequest = false
        this.searchedEmployees.splice(0, this.searchedEmployees.length)
        this.requests.splice(0, this.requests.length)
        this.isSearchingEmployees = false
      },
      filterEmployeesByProperty(employees, propertyName, expectedValue) {
        if (employees && employees.length) {
          return employees.filter(employee => employee[propertyName] === expectedValue)
        }
      },
      searchEmployees(searchString) {
        const self = this
        self.resetData()
        self.isSearchingEmployees = true
        self.closeMessage()
        self.searchedEmployees.splice(0, self.searchedEmployees.length)
        employeeService.searchEmployees(searchString).then((result) => {
          if (result.data && result.data.length) {
            let filteredEmployees = []
            if (self.currentUser.isAdmin) {
              filteredEmployees = result.data
            }
            //filteredEmployees = filteredEmployees.filter(employee => employee.employeeId && employee.employeeId.toLowerCase() !== this.currentUser.id.toLowerCase() && (employee.isRegularEmployee || employee.isExpatriateEmployee || employee.isTemporalEmployee))
            self.searchedEmployees = [...filteredEmployees]
          }
          else {
            self.searchedEmployees = [{ employeeId: null, fullName: 'No results' }]
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          self.isSearchingEmployees = false
        })
        //employeeService.searchEmployees(searchString).then((result) => {
        //  if (result.data && result.data.length) {
        //    let filteredEmployees = []
        //    if ((self.currentUser.userRightss & Enums.UserRights.EditAllData.value) === Enums.UserRights.EditAllData.value) {
        //      filteredEmployees = result.data
        //    }
        //    else {
        //      for (const right in Enums.UserRights) {
        //        const userRight = Enums.UserRights[right]
        //        if (userRight.employeeSearchFilterProperty && ((self.currentUser.userRights & userRight.value) === userRight.value)) {
        //          filteredEmployees = self.filterEmployeesByProperty(result.data, userRight.employeeSearchFilterProperty, self.currentUser[userRight.employeeSearchFilterProperty])
        //          break
        //        }
        //      }
        //    }
        //    self.searchedEmployees = [...filteredEmployees]
        //  }
        //}).catch((error) => {
        //  self.showErrorMessage(error)
        //}).finally(() => {
        //  self.isSearchingEmployees = false
        //})
      },
      fetchEmployeeData(employee) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        let tasks = []
        tasks.push(employeeService.getEmployeeWithAlternateDetails(employee.employeeId))
        //tasks.push(store.dispatch(FETCH_REQUESTS_PENDING_USER_ACTION, { employeeId: employee.employeeId }))
        //tasks.push(leaveRequestService.getRequestsPendingUserAction(employee.employeeId))
        //tasks.push(vacationChangeRequestService.getRequestsPendingUserAction(employee.employeeId))
        tasks.push(rnrRequestService.getRequestsPendingUserAction(employee.employeeId))
        Promise.all(tasks).then((results) => {
          const employee = results[0].data
          const leaveRequestsPendingAction = [] // results[1].items
          const vacationChangeRequestsPendingAction = [] // results[2].items
          const rnrRequests = results[1].items
          self.setData(employee, leaveRequestsPendingAction, vacationChangeRequestsPendingAction, rnrRequests)
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      setData(employee, leaveRequests, vacationChangeRequests, rnrRequests) {
        const self = this
        self.employeeInfo.employeeId = employee.employeeId
        self.employeeInfo.employeeNo = employee.employeeNo
        self.employeeInfo.fullName = employee.fullName
        self.employeeInfo.alternateEmployeeId = employee.alternateEmployeeId
        if (leaveRequests.length) {
          leaveRequests.forEach(request => {
            if (request.requestType !== Enums.RequestType.RnR.value) {
              request.employeeName = request.employeeInfo.fullName
              self.requests.push(request)
            }
          })
        }

        if (rnrRequests.length) {
          rnrRequests.forEach(request => {
            request.employeeName = request.employeeInfo.fullName
            self.requests.push(request)
          })
        }

        if (vacationChangeRequests.length) {
          vacationChangeRequests.forEach(request => {
            request.leaveType = request.requestType
            request.duration = request.numberOfDays
            request.employeeName = request.employeeInfo.fullName
            self.requests.push(request)
          })
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.fetchEmployeeData(vm.currentUser)
      })
    },
    mounted() {
      const self = this
      eventBus.$on(EDIT_REQUEST_EVENT, self.editRequest)
      eventBus.$on(APPROVE_REQUEST_EVENT, self.actionRequest)
      eventBus.$on(REJECT_REQUEST_EVENT, self.actionRequest)
      eventBus.$on(RETURN_REQUEST_EVENT, self.actionRequest)
      eventBus.$on(CANCEL_REQUEST_EVENT, self.actionRequest)
      eventBus.$on(PROCESS_REQUEST_EVENT, self.actionRequest)
  
      eventBus.$on(EMPLOYEE_SEARCH_STARTED_EVENT, self.searchEmployees)
      eventBus.$on(EMPLOYEE_SELECTED_EVENT, self.fetchEmployeeData)
      eventBus.$on(CLOSE_MODAL_EVENT, self.onDismissAction)
      self.$on('error', (error) => {
        self.showErrorMessage(error)
      })
    },
    beforeDestroy() {
      eventBus.$off(APPROVE_REQUEST_EVENT)
      eventBus.$off(REJECT_REQUEST_EVENT)
      eventBus.$off(RETURN_REQUEST_EVENT)
      eventBus.$off(CANCEL_REQUEST_EVENT)
      eventBus.$off(PROCESS_REQUEST_EVENT)
      eventBus.$off(EMPLOYEE_SEARCH_STARTED_EVENT)
      eventBus.$off(EMPLOYEE_SELECTED_EVENT)
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }
</script>
